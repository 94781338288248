const host = window.location.origin;

export const environment = {
  production: false,
  enablePWA: false,
  PREFIX: 'dev/',
  I18N_VERSION: '1.0.1',
  RELEASE_LABEL: 'Release 5.10',
  APP_URL: host,
  ENVIRONMENT_NAME: 'SchoolApp',

  // API
  BASE_URL: 'https://api-dev.gan.no',
  API_URL: 'https://api-dev.gan.no/api/web-app',
  ADMIN_URL: 'https://api-dev.gan.no/api/admin',

  // Microsoft Authentication
  MSAL_CLIENT_ID: '3e63ad9c-5520-420e-908e-a5abf3674c0c',
  MSAL_TENANT_ID: '82bcfcce-4d12-4380-9471-7cd2e339288d',
  MSAL_API_CLIENT_ID: 'd1f2ce50-416d-480a-82e1-73ea18623c9e', // Client ID of API app registration

  ONESIGNAL_APP_ID: host.includes('localhost')
    ? '496bdb53-7603-4d43-9549-c5330919345d'
    : '1bcf94af-f040-49fd-b2bc-c6e48315b417',

  // Sanity
  SANITY_INTRA_PROJECT_ID: 'htrt5dd5',
  SANITY_INTRA_DATASET: 'development',
  SANITY_NORGE_PROJECT_ID: 'slt575ja',
  SANITY_NORGE_DATASET: 'development',

  // Language
  LANGUAGE_DEFAULT: 'No',
  LANGUAGE_STORAGE_KEY: 'app.school.lang',

  // Theme
  DARK_MODE_STORAGE_KEY: 'app.school.darkMode',
  DARK_CLASS_NAME: 'school-dark',
  LIGHT_CLASS_NAME: 'school-light',
  LIGHT_BG: '#353649',
  DARK_BG: '#353649',
};
