import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { forkJoin, of } from 'rxjs';
import { first, map, mapTo, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '@gan/core/util-environment';

@Injectable()
export class SetTitleGuard implements CanActivate {
  constructor(private readonly translateService: TranslateService, private readonly titleService: Title) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let titleComponentInstance = '';

    if (environment.PREFIX.includes('dev')) {
      titleComponentInstance = 'D';
    }

    if (environment.PREFIX.includes('test')) {
      titleComponentInstance = 'T';
    }

    if (environment.PREFIX.includes('stage')) {
      titleComponentInstance = 'S';
    }

    const titleComponentID$ = of(route?.paramMap?.get('id') || route?.firstChild?.paramMap?.get('id'));
    const titleComponentPage$ = this.translateService
      .get(route?.data?.['title'] || route?.firstChild?.data?.['title'] || '')
      .pipe(first());

    return forkJoin([titleComponentID$, titleComponentPage$]).pipe(
      map(([titleComponentId, titleComponentPage]) => {
        return [titleComponentInstance, titleComponentId, titleComponentPage].filter(Boolean).join(' | ');
      }),
      tap((titleFinal) => this.titleService.setTitle(titleFinal)),
      mapTo(true)
    );
  }
}
